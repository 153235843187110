<template>
<div></div>
</template>

<script>
export default {
  name: 'LandingPage',
  data: () => {
    return {}
  },
  created: function () {
    // Redirect
    const landingPage = this.$store.getters.landingPage
    if (landingPage) {
      this.$router.push(landingPage)
    } else {
      this.$router.push('/')
    }
  }
}
</script>
